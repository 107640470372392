<template>
  <div>
    <b-container class="mt-5 mb-5">
      <div class="mb-4">
        <h1 class="fs-4 font-weight-bold mb-4">
          Evenementen Archief
        </h1>
      </div>
      <template v-if="events.length">
        <b-card v-for="(event, key) in events" :key="key" class="mb-3">
          <b-row align-v="center">
            <b-col sm="8">
              <router-link :to="'/manage/events/' + event.id" class="fs-bold text-primary text-decoration-none d-block fs-5">
                {{ event.name }}<template v-if="event.location"> - {{ event.location.name }}</template>
              </router-link>
              <div class="text-muted">
                <span class="mr-3" v-b-tooltip.hover title="Looptijd van evenement">
                  <font-awesome-icon icon="calendar" class="mr-1" />
                  <template v-if="isSameDay(event)">
                    {{ getStartDate(event)|formatTextDateTime }}
                  </template>
                  <template v-else>
                    {{ getStartDate(event)|formatTextDateTime }} - {{ getEndDate(event)|formatTextDateTime }}
                  </template>
                </span>
                <span class="mr-3" v-b-tooltip.hover title="Tickets verkocht">
                  <font-awesome-icon icon="ticket" class="mr-1" />
                  {{ event.total_sold }}
                </span>
              </div>
            </b-col>
            <b-col sm="4">
              <div class="float-right">
                <b-badge class="bg-success mr-2" v-if="event.published_at">
                  Published
                </b-badge>
                <b-badge class="bg-warning mr-2" v-else>
                  Concept
                </b-badge>
                <template v-if="isAdmin() || isManager()">
                  <b-button :to="'/manage/events/' + event.id + '/duplicate'" variant="white" class="text-secondary" v-b-tooltip.hover title="Dupliceer">
                    <font-awesome-icon icon="fa-solid fa-clone" />
                  </b-button>
                </template>
                <b-button :to="'/manage/events/' + event.id" variant="white" class="text-secondary" v-b-tooltip.hover title="Dashboard">
                  <font-awesome-icon icon="fa-solid fa-chevron-right" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <b-card v-if="!events.length" bg-variant="dotted" no-body class="mb-3 cursor-pointer" @click="newEvent">
        <b-card-body class="text-center">
          <font-awesome-icon icon="fa-solid fa-calendar" size="3x" class="mb-2 text-muted" />
          <div>Geen aankomende evenementen.</div>
          <div class="text-muted">Ga aan de slag door een nieuw evenement aan te maken.</div>
        </b-card-body>
      </b-card>
      <div class="text-center">
        <router-link to="/manage/events" class="text-primary">
          Terug naar aankomende evenementen
          <font-awesome-icon icon="chevron-right"/>
        </router-link>
      </div>
    </b-container>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  export default {
      metaInfo: {
          title: 'Evenementen Archief',
      },
      components: {},
      data() {
          return {
            events: []
          }
      },
      computed: {},
      methods: {
        ...mapActions(useShopStore, ['setLoading']),
        newEvent: function() {
          this.$router.push('/manage/events/create');
        },
        isSameDay(event) {
          let start = this.getStartDate(event);
          let end = this.getEndDate(event);
          if (moment(start).isSame(end, 'day')) {
            return true;
          }
          return false;
        },
        getStartDate(event) {
          let start_at = null;
          if(event.slots && event.slots.length > 0) {
            event.slots.forEach(item => {
              if (start_at == null || moment(item.start_at) < moment(start_at)) {
                start_at = item.start_at;
              }
            })
            return start_at;
          }
          return null;
        },
        getEndDate(event) {
          let end_at = null;
          if(event.slots && event.slots.length > 0) {
            event.slots.forEach(item => {
              if (end_at == null || moment(item.end_at) > moment(end_at)) {
                end_at = item.end_at;
              }
            })
            return end_at;
          }
          return null;
        },
        getEvents() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/events?archived=1")
            .then(response => {
              this.events = response.data;
              this.setLoading(false);
            }
          ).catch((reason) => {
            console.log(reason);
          });
        },
        isAdmin() {
          return (localStorage.getItem('role') == 'administrator' ? true : false);
        },
        isManager() {
          return (localStorage.getItem('role') == 'manager' ? true : false);
        }
      },
      created() {
        this.getEvents();
      }
  }
</script>
  
<style scoped>
  .intro-icon{
    background-color:#CA355B;
    padding:10px;
  }
  .package-icon{
    vertical-align:middle;
}
</style>